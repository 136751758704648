<template>
  <div>
      <vx-card id="searchTable" ref="filterCard" title="Estado de Cuenta de Estudiante" class="user-list-filters mb-8">
          <div class="vx-row">
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Escuela</label>
                  <v-select v-model="idSchool" :clearable="false" :options="schoolOptions" :reduce="idSchool => idSchool.value" v-validate="'required'" data-vv-as="Escuela" name="school" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="handleSelectSchool"/>
                  <span class="text-danger text-sm" v-show="errors.has('school')">{{ errors.first('school') }}</span>
              </div>
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Estudiante</label>
                  <v-select v-model="idStudent" :clearable="true" :options="studentOptions" :reduce="studentOptions => studentOptions.value" data-vv-as="Estudiante" name="student" :dir="$vs.rtl ? 'rtl' : 'ltr'" :disabled="!idSchool"/>
                  <span class="text-danger text-sm" v-show="errors.has('student')">{{ errors.first('student') }}</span>
              </div>
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-6">
                <div class="flex flex-wrap items-center">
                  <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
                  <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
                  <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
                  <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
                </div>
              </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-3">
                <ul class="demo-alignment">
                  <label class="text-sm opacity-75">Medio de pago</label>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="1">Tiptap</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="4">ACH</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="3">Cheque</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="5">Efectivo</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="2">Tarjeta</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="6">Yappy</vs-checkbox>
                  </li>
                </ul>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-5 ag-grid-table-actions-right">
                <vs-button id="searchButton" class="mb-4 md:mb-0 float-right" @click="loadTable" :disabled="!(isFormValid() && isFilterActive)">Aplicar Filtros</vs-button>
            </div>
          </div>
      </vx-card>

      <div class="vx-card p-6">
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                  <span class="mr-2"
                  >{{
                      currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                      charges.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : charges.length
                  }}
                  de {{ charges.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                  </vs-dropdown-item>
              </vs-dropdown-menu>
              </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
              <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."
              />
              <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
              >Exportar</vs-button
              >
          </div>
          </div>
          <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="charges"
              rowSelection="single"
              colResizeDefault="shift"
              :animateRows="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl"
              :modules="modules"
              @first-data-rendered="onFirstDataRendered"
          >
          <!-- @selection-changed="onSelectionChanged" -->
          </ag-grid-vue>
          <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
          />
      </div>
  </div>
</template>
<script>
import * as lang from 'vuejs-datepicker/src/locale';
import DatePicker from "vuejs-datepicker";
import vSelect from 'vue-select'
import { AgGridVue } from "@ag-grid-community/vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import reportModule from "@/store/report/moduleReport.js";
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import moduleServices from '@/store/services/moduleServices.js'
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import CellRendererPaymentStatus from './components/CellRendererPaymentStatus.vue';

export default {
  components: {
      vSelect,
      AgGridVue,
      AllCommunityModules,
      DatePicker,

      // Cell Renderers
      CellRendererPaymentStatus
  },
  data() {
      return {
          languages: lang,
          from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
          to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          idSchool: null,
          idStudent: null,
          charges: [],
          schoolOptions: [],
          studentOptions: [],
          periodOptions: [],
          paymentMethodCheck: [],

          //AgGrid
          modules: AllCommunityModules,
          searchQuery: "",
          maxPageNumbers: 5,
          gridApi: null,
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true,
          },
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: false,
              floatingFiltersHeight: 0,
          },
          columnDefs: [
              {
                  headerName: "ID Pago",
                  sortable: true,
                  field: "id_payment",
                  width: 120,
                  filter: true,
              },
              {
                  headerName: 'Fecha de pago',
                  field: 'payment_date',
                  filter: false,
                  width: 150,
                  comparator: this.myDateComparator,
              },
              {
                  headerName: "Escuela",
                  sortable: true,
                  field: "school_name",
                  width: 120,
                  filter: true,
                  // cellClass: "cellMayusc",
              },
              {
                  headerName: "Estudiante",
                  field: "student_display_name",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: "Documento",
                  field: "document",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: "Acudiente",
                  field: "guardian_display_name",
                  width: 240,
                  filter: true,
              },
              {
                  headerName: "ID Acudiente",
                  field: "id_guardian",
                  filter: true,
              },
              {
                  headerName: "Monto Cuota",
                  field: "amount",
                  width: 140,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.amount, '$ '),
              },
              {
                  headerName: "Penalidad",
                  field: "penalty",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.penalty, '$ '),
              },
              {
                  headerName: "Descuento",
                  field: "discount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.discount, '$ '),
              },
              {
                  headerName: "Total Pagado",
                  field: "final_amount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount, '$ '),
              },
              {
                  headerName: 'Medio de pago',
                  field: 'payment_method',
                  filter: true,
                  width: 150,
              },
              {
                  headerName: 'Referencia',
                  field: 'reference',
                  filter: true,
                  width: 150,
              },
          ],
      }
  },
  methods: {
      loadTable() {
          this.$validator.validateAll().then(result => {
              if(!result) return

              this.charges = []

              if (this.idSchool > 0) {
                  this.$store.dispatch("reportModule/getChargesGuardians", this.getQueryParams())
                  .then((response) => {
                      if (response.data && response.data.length > 0) {
                        let data = JSON.parse(JSON.stringify(response.data));

                        data.forEach(e => {
                            e.student_display_name = `${e.student_last_name} ${e.student_name}`
                            e.guardian_display_name = `${e.guardian_last_name} ${e.guardian_name}`
                            e.payment_date = e.payment_date ? new Date(e.payment_date).toLocaleDateString('es-AR', this.$localeDateOptions) : '';
                        })

                        this.charges = data;
                      }
                  })
                  .catch((error) => {
                      console.log(error);
                  });
              }
          })
      },
      isDate(val) {
        return val instanceof Date && !isNaN(val.valueOf())
      },
      getQueryParams() {
        const dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
        let params = `id_school=${this.idSchool}&from=${dates.from}&to=${dates.to}`

        if (this.idStudent) params += `&id_pupil='${this.idStudent}'`
        if (this.paymentMethodCheck.length > 0) params += `&id_payment_method=${this.paymentMethodCheck.toString()}`

        return params
      },
      getSchools() {
          if(this.schoolOptions && this.schoolOptions.length) return

          this.$store.dispatch(`dataListInstitutions/getSchools`)
          .then(() => {
              const schools = this.$store.getters['dataListInstitutions/getSchoolsInGop'];
              schools.forEach(element => {
                  this.schoolOptions.push({label: element.name, value: element.id})
              });
          })
          .catch(err => console.log(err))

      },
      myDateComparator(date1, date2) {
          var date1Number = this.monthToComparableNumber(date1);
          var date2Number = this.monthToComparableNumber(date2);
          if (date1Number === null && date2Number === null) {
              return 0;
          }
          if (date1Number === null) {
              return -1;
          }
          if (date2Number === null) {
              return 1;
          }
          return date1Number - date2Number;
      },
      monthToComparableNumber(date) {
          if (date === undefined || date === null || date.length !== 10) {
              return null;
          }
          var yearNumber = date.substring(6, 10);
          var monthNumber = date.substring(3, 5);
          var dayNumber = date.substring(0, 2);
          var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
          return result;
      },
      getStudentsBySchool() {
        this.resetDependingFilters()

        if(!this.idSchool) return

          this.$store.dispatch(`dataListInstitutions/getPupilsBySchool`, this.idSchool)
          .then(response => {
              const students = response.data;

              students.forEach(element => {
                  this.studentOptions.push({label: `${element.name} ${element.last_name} (${element.id})`, value: element.id})
              });
          })
          .catch(err => console.log(err))
      },
      handleSelectSchool() {
        this.getStudentsBySchool()
      },
      isFormValid() {
        return !this.errors.any() && (this.idSchool > 0)
      },
      updateSearchQuery(val) {
        this.gridApi.setQuickFilter(val);
      },
      onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
      },
      resetDependingFilters() {
        this.idStudent = null
        this.studentOptions = []
        this.periodOptions = []
      }
  },
  computed: {
      paginationPageSize() {
          if (this.gridApi) return this.gridApi.paginationGetPageSize();
          else return 50;
      },
      totalPages() {
          if (this.gridApi) return this.gridApi.paginationGetTotalPages();
          else return 0;
      },
      currentPage: {
          get() {
              if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
              else return 1;
          },
          set(val) {
              this.gridApi.paginationGoToPage(val - 1);
          },
      },
      isFilterActive() {
        return (this.idStudent || this.from && this.to);
      }
  },
  created() {
    // Register Service Module
    if(!moduleServices.isRegistered) {
      this.$store.registerModule('servicesModule', moduleServices)
      moduleServices.isRegistered = true
    }
    if (!reportModule.isRegistered) {
        this.$store.registerModule("reportModule", reportModule);
        reportModule.isRegistered = true;
    }
    if(!moduleDataListInstitutions.isRegistered) {
        this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
        moduleDataListInstitutions.isRegistered = true
    }
    if (!moduleUserManagement.isRegistered) {
        this.$store.registerModule("userManagement", moduleUserManagement);
        moduleUserManagement.isRegistered = true;
    }

    this.getSchools();
  },
  mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
          const header = this.$refs.agGridTable.$el.querySelector(
              ".ag-header-container"
          );
          header.style.left =
              "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
  }
}
</script>
<style>
.cellMayusc {
  text-transform: capitalize;
}
.ag-grid-table.total .ag-row {
  font-weight: 700;
}
.ag-grid-table.total .ag-header {
  border: none;
}
</style>
